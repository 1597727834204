import React from "react"
import PropTypes from "prop-types"

const LetterE = ({ size, color, bgColor }) => {
  return (
    <svg
      width={size}
      height={size}
      aria-hidden="true"
      viewBox="-23 0 448 416.04455"
    >
      <defs>
        <clipPath id="letterE">
          <path
            d="M847 156 1249 156 1249 558 847 558Z"
            fillRule="evenodd"
            clipRule="evenodd"
          />
        </clipPath>
      </defs>
      <g clipPath="url(#letterE)" transform="translate(-847 -156)">
        <path
          d="M848 356.5C848 245.767 937.543 156 1048 156 1158.46 156 1248 245.767 1248 356.5 1248 467.233 1158.46 557 1048 557 937.543 557 848 467.233 848 356.5Z"
          fill={bgColor}
          fillRule="evenodd"
        />
        <path
          d="M970 223C970.309 224.555 970.687 226.097 970.927 227.664 971.306 230.142 971.332 232.676 971.854 235.127 972.263 237.05 973.233 238.816 973.707 240.724 974.871 245.41 974.231 243.24 975.561 247.254 975.87 249.431 976.059 251.628 976.488 253.784 976.987 256.299 977.723 258.76 978.341 261.247 979.505 265.932 978.865 263.763 980.195 267.777 980.504 269.954 980.731 272.144 981.121 274.307 982.984 284.617 981.172 269.147 982.975 284.569 984.523 297.813 983.057 289.647 984.829 298.562 985.138 303.226 985.315 307.901 985.755 312.555 985.934 314.438 986.396 316.282 986.682 318.152 987.014 320.325 987.402 322.493 987.609 324.682 990.26 352.697 987.11 326.258 989.462 345.205 991.912 392.049 991.071 368.93 989.462 457.148 989.417 459.655 988.811 462.12 988.536 464.611 988.193 467.717 988.181 470.868 987.609 473.94 987.249 475.873 985.755 477.57 985.755 479.537L985.755 487"
          stroke={color}
          strokeWidth="40"
          strokeMiterlimit="8"
          fill="none"
          fillRule="evenodd"
        />
        <path
          d="M964 238 1005.91 239C1008.42 239.101 1010.87 239.777 1013.37 240 1018.49 240.458 1040.58 241.734 1045.03 242 1089.11 240.247 1061.79 241 1127 241"
          stroke={color}
          strokeWidth="40"
          strokeMiterlimit="8"
          fill="none"
          fillRule="evenodd"
        />
        <path
          d="M991 363C1073.06 364.182 1036.73 363.988 1100 363.988"
          stroke={color}
          strokeWidth="40"
          strokeMiterlimit="8"
          fill="none"
          fillRule="evenodd"
        />
        <path
          d="M971 474C977.196 474.267 983.404 474.374 989.588 474.8 991.163 474.908 992.668 475.431 994.235 475.6 997.631 475.965 1001.05 476.121 1004.46 476.4 1007.56 476.654 1010.65 477.038 1013.75 477.2 1020.87 477.571 1028 477.733 1035.13 478L1102.98 477.2C1106.4 477.13 1109.78 476.505 1113.2 476.4 1118.46 476.238 1123.73 476.4 1129 476.4"
          stroke={color}
          strokeWidth="40"
          strokeMiterlimit="8"
          fill="none"
          fillRule="evenodd"
        />
      </g>
    </svg>
  )
}

LetterE.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
  bgColor: PropTypes.string,
}

LetterE.defaultProps = {
  size: `100px`,
  color: `#fff`,
  bgColor: `#000`,
}

export default LetterE
