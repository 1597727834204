const header = {
  languageSelection: {
    button: {
      en: `ENG`,
      th: `ไทย`,
    },
    languages: [
      {
        code: `en`,
        display: `English`,
      },
      {
        code: `th`,
        display: `ไทย`,
      },
    ],
  },
  theme: {
    title: {
      en: `Theme`,
      th: `ธีม`,
    },
    system: {
      en: `System`,
      th: `ระบบ`,
    },
    light: {
      en: `Light`,
      th: `สว่าง`,
    },
    dark: {
      en: `Dark`,
      th: `มืด`,
    },
  },
  colorSelection: {
    en: `Edit Website Colors`,
    th: `ปรับแต่งสีเว็บไซต์`,
  },
  volume: {
    en: `Volume`,
    th: `ระดับเสียง`,
  },
}

export default header
