import { Link, Popover } from "@mui/material"
import styled from "styled-components"

const StyledLink = styled(Link)`
  transition: 0.3s;
`

const StyledPopover = styled(Popover)`
  .MuiPaper-root {
    min-width: 200px;

    div.popover__title {
      padding: 12px 16px;
    }
  }
`

export { StyledLink, StyledPopover }
