import React from "react"
import PropTypes from "prop-types"

const LetterF = ({ size, color, bgColor }) => {
  return (
    <svg
      width={size}
      height={size}
      aria-hidden="true"
      viewBox="-23 0 448 416.04455"
    >
      <defs>
        <clipPath id="letterF">
          <path
            d="M29 156 431 156 431 558 29 558Z"
            fillRule="evenodd"
            clipRule="evenodd"
          />
        </clipPath>
      </defs>
      <g clipPath="url(#letterF)" transform="translate(-29 -156)">
        <path
          d="M29 356.5C29 245.767 118.767 156 229.5 156 340.233 156 430 245.767 430 356.5 430 467.233 340.233 557 229.5 557 118.767 557 29 467.233 29 356.5Z"
          fill={bgColor}
          fillRule="evenodd"
        />
        <path
          d="M158 219C158.633 222.102 158.879 225.306 159.9 228.307 160.217 229.238 160.607 230.147 160.85 231.099 162.578 237.872 161.056 233.871 162.75 241.337 162.967 242.294 163.457 243.177 163.7 244.129 164.482 247.192 165.036 251.326 165.6 254.366 165.889 255.923 166.233 257.469 166.55 259.02 166.867 262.743 167.079 266.475 167.5 270.188 167.713 272.064 168.276 273.893 168.45 275.772 168.91 280.725 168.96 285.709 169.4 290.663 169.594 292.849 170.054 295.004 170.35 297.178 170.687 299.657 170.983 302.142 171.3 304.624 171.617 311.759 171.834 318.899 172.25 326.03 172.468 329.759 172.982 333.468 173.2 337.198 173.616 344.328 173.665 351.478 174.15 358.604 174.87 369.179 175.868 377.473 177 387.455 176.683 410.102 176.621 432.754 176.05 455.396 175.987 457.896 175.321 460.35 175.1 462.842 173.579 479.974 175.437 471.757 173.2 480.525 172.623 485.046 172.388 488.36 171.3 492.624 171.057 493.575 170.46 494.441 170.35 495.416 170.14 497.266 170.35 499.139 170.35 501"
          stroke={color}
          strokeWidth="40"
          strokeMiterlimit="8"
          fill="none"
          fillRule="evenodd"
        />
        <path
          d="M149 230C159.267 230.334 169.529 231 179.8 231 205.259 231 230.072 229.936 255.4 229.001 259.756 228.668 264.124 228.491 268.467 228.001 283.028 226.36 258.891 227.278 281.533 226.002 301.099 224.899 302.767 225.002 317 225.002"
          stroke={color}
          strokeWidth="40"
          strokeMiterlimit="8"
          fill="none"
          fillRule="evenodd"
        />
        <path
          d="M173 364C174.875 363.667 176.737 363.237 178.625 363 197.49 360.632 181.865 363.257 194.563 361 243.607 363.012 210.806 362 293 362"
          stroke={color}
          strokeWidth="40"
          strokeMiterlimit="8"
          fill="none"
          fillRule="evenodd"
        />
      </g>
    </svg>
  )
}

LetterF.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
  bgColor: PropTypes.string,
}

LetterF.defaultProps = {
  size: `100px`,
  color: `#fff`,
  bgColor: `#000`,
}

export default LetterF
