import React from "react"
import PropTypes from "prop-types"

const LetterM = ({ size, color, bgColor }) => {
  return (
    <svg
      width={size}
      height={size}
      aria-hidden="true"
      viewBox="-23 0 448 416.04455"
    >
      <defs>
        <clipPath id="letterM">
          <path
            d="M438 156 840 156 840 558 438 558Z"
            fillRule="evenodd"
            clipRule="evenodd"
          />
        </clipPath>
      </defs>
      <g clipPath="url(#letterM)" transform="translate(-438 -156)">
        <path
          d="M438 356.5C438 245.767 527.767 156 638.5 156 749.233 156 839 245.767 839 356.5 839 467.233 749.233 557 638.5 557 527.767 557 438 467.233 438 356.5Z"
          fill={bgColor}
          fillRule="evenodd"
        />
        <path
          d="M587 220C586.873 226.884 586.697 270.02 585.147 287.2 584.837 290.641 583.969 290.673 583.294 293.733 582.887 295.581 582.775 297.486 582.368 299.333 582.156 300.294 581.653 301.173 581.441 302.133 581.034 303.981 580.883 305.878 580.515 307.733 578.782 316.461 580.428 307.151 578.662 314.267 578.28 315.806 578.117 317.394 577.735 318.933 577.499 319.888 577.077 320.787 576.809 321.733 576.459 322.967 576.248 324.238 575.883 325.467 575.321 327.351 574.647 329.2 574.03 331.067 573.721 332 573.34 332.912 573.103 333.867 571.703 339.509 572.579 336.384 570.324 343.2L569.397 346C569.089 346.933 568.708 347.846 568.471 348.8 568.174 349.997 567.283 353.994 566.618 355.333 566.12 356.337 565.383 357.2 564.765 358.133 564.456 359.378 564.188 360.633 563.839 361.867 563.57 362.813 563.149 363.712 562.912 364.667 562.53 366.206 562.4 367.803 561.986 369.333 561.472 371.232 560.75 373.067 560.133 374.933 559.824 375.867 559.443 376.779 559.206 377.733 558.898 378.978 558.646 380.238 558.28 381.467 558.279 381.47 555.964 388.465 555.501 389.867L551.795 401.067C549.574 407.779 552.268 399.397 549.942 407.6 549.673 408.546 549.252 409.446 549.015 410.4 547.413 416.859 549.286 413.257 546.236 417.867 544.836 423.51 545.712 420.382 543.457 427.2 543.456 427.201 541.604 432.8 541.604 432.8 540.986 433.733 540.203 434.575 539.751 435.6 539.75 435.603 537.435 442.599 536.971 444 536.663 444.933 536.736 446.104 536.045 446.8L534.192 448.667C532.792 454.31 533.669 451.182 531.413 458 531.104 458.933 531.177 460.104 530.486 460.8L528.633 462.667C528.324 463.6 528.181 464.607 527.707 465.467 523.83 472.497 524.001 468.661 524.001 472"
          stroke={color}
          strokeWidth="40"
          strokeMiterlimit="8"
          fill="none"
          fillRule="evenodd"
        />
        <path
          d="M586 223C586.621 224.549 587.291 226.08 587.862 227.648 588.533 229.49 589.103 231.367 589.724 233.226L593.448 244.382 596.241 252.749C596.551 253.678 596.628 254.722 597.172 255.538L599.034 258.327C599.344 259.566 599.613 260.817 599.965 262.045 600.234 262.988 600.683 263.878 600.896 264.834 601.305 266.674 601.369 268.583 601.827 270.412 602.303 272.313 603.213 274.089 603.689 275.99 603.999 277.23 604.268 278.48 604.62 279.709 604.89 280.651 605.338 281.541 605.551 282.497 605.96 284.338 606.024 286.247 606.482 288.075 606.482 288.077 608.809 295.047 609.275 296.442 609.585 297.372 609.968 298.28 610.206 299.231 610.516 300.471 610.769 301.726 611.137 302.95 611.701 304.827 612.378 306.668 612.999 308.528L613.93 311.317C614.24 312.246 614.623 313.155 614.861 314.106 615.171 315.345 615.424 316.6 615.792 317.824 615.793 317.829 618.118 324.794 618.585 326.191L621.378 334.558C621.688 335.487 622.147 336.38 622.309 337.347 623.401 343.891 622.643 341.136 624.171 345.714 624.481 347.573 624.644 349.463 625.102 351.291 625.578 353.193 626.488 354.968 626.964 356.869 627.274 358.109 627.543 359.359 627.895 360.588 628.164 361.53 628.588 362.426 628.826 363.377 629.209 364.91 629.34 366.501 629.757 368.025 630.273 369.916 631.296 371.67 631.619 373.603 631.929 375.462 632.14 377.341 632.55 379.181 632.762 380.137 633.211 381.028 633.481 381.97 633.832 383.198 634.044 384.465 634.412 385.688 634.976 387.566 636.274 391.266 636.274 391.266 636.584 393.126 636.795 395.004 637.205 396.844 637.417 397.801 637.897 398.683 638.136 399.633 638.519 401.166 638.683 402.748 639.067 404.281 640.096 408.392 639.997 405.766 639.997 408"
          stroke={color}
          strokeWidth="40"
          strokeMiterlimit="8"
          fill="none"
          fillRule="evenodd"
        />
        <path
          d="M639 408C639.31 405.51 639.483 402.999 639.931 400.531 640.107 399.562 640.649 398.69 640.862 397.73 641.272 395.882 641.384 393.976 641.793 392.128 642.006 391.167 642.511 390.287 642.724 389.327 643.134 387.479 643.285 385.581 643.655 383.725 644.24 380.794 644.63 379.858 645.517 377.189 645.828 374.388 645.986 371.566 646.448 368.786 646.61 367.815 647.11 366.931 647.379 365.985 647.731 364.751 647.943 363.479 648.31 362.25 648.874 360.365 649.696 358.557 650.172 356.648 650.483 355.403 650.736 354.142 651.103 352.913 651.667 351.028 652.345 349.179 652.966 347.311L653.896 344.51 659.483 327.704 662.276 319.301C662.586 318.367 662.969 317.455 663.207 316.5 663.517 315.255 663.77 313.994 664.138 312.765 664.702 310.88 665.379 309.031 666 307.163L669.724 295.959 672.517 287.556C672.828 286.622 672.904 285.574 673.448 284.755L675.31 281.954 679.034 270.75 680.897 265.148C681.207 264.214 681.283 263.166 681.828 262.347L683.69 259.546 688.345 245.541 690.207 239.939C690.517 239.005 690.9 238.093 691.138 237.138L692.069 233.403 693 225"
          stroke={color}
          strokeWidth="40"
          strokeMiterlimit="8"
          fill="none"
          fillRule="evenodd"
        />
        <path
          d="M693 228C693.933 229.552 694.989 231.037 695.8 232.656 698.223 237.491 694.954 233.674 698.6 237.312L703.267 251.279 705.133 256.866 706.067 259.659C706.378 261.832 706.568 264.025 707 266.177 707.193 267.14 707.811 267.997 707.933 268.971 708.437 272.987 708.342 277.063 708.867 281.076 709.277 284.215 710.733 290.388 710.733 290.388 711.044 295.975 711.181 301.574 711.667 307.149 711.804 308.725 712.339 310.243 712.6 311.804 712.962 313.969 713.314 316.139 713.533 318.322 713.937 322.349 714.082 326.399 714.467 330.427 714.704 332.919 715.162 335.386 715.4 337.877 717.077 355.449 715.387 344.317 717.267 355.569 717.578 360.225 717.776 364.889 718.2 369.536 718.71 375.135 720.067 386.297 720.067 386.297 720.378 392.194 720.487 398.106 721 403.989 721.111 405.264 721.704 406.455 721.933 407.714 722.327 409.873 722.505 412.067 722.867 414.232 723.128 415.793 723.539 417.326 723.8 418.888 726.232 433.448 723.012 416.181 725.667 434.717 725.848 435.984 726.289 437.2 726.6 438.442 726.911 441.856 727.047 445.291 727.533 448.685 727.672 449.656 728.196 450.534 728.467 451.478 728.819 452.709 729.171 453.944 729.4 455.203 731.116 464.619 729.35 458.777 731.267 464.514 731.578 467.308 731.737 470.122 732.2 472.895 732.362 473.863 732.863 474.745 733.133 475.688 733.486 476.919 733.815 478.158 734.067 479.413 734.438 481.264 735 485 735 485"
          stroke={color}
          strokeWidth="40"
          strokeMiterlimit="8"
          fill="none"
          fillRule="evenodd"
        />
      </g>
    </svg>
  )
}

LetterM.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
  bgColor: PropTypes.string,
}

LetterM.defaultProps = {
  size: `100px`,
  color: `#fff`,
  bgColor: `#000`,
}

export default LetterM
