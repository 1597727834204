import React from "react"
import PropTypes from "prop-types"

const LetterH = ({ size, color, bgColor }) => {
  return (
    <svg
      width={size}
      height={size}
      aria-hidden="true"
      viewBox="-23 0 448 416.04455"
    >
      <defs>
        <clipPath id="letterH">
          <path
            d="M847 156 1249 156 1249 558 847 558Z"
            fillRule="evenodd"
            clipRule="evenodd"
          />
        </clipPath>
      </defs>
      <g clipPath="url(#letterH)" transform="translate(-847 -156)">
        <path
          d="M848 356.5C848 245.767 937.543 156 1048 156 1158.46 156 1248 245.767 1248 356.5 1248 467.233 1158.46 557 1048 557 937.543 557 848 467.233 848 356.5Z"
          fill={bgColor}
          fillRule="evenodd"
        />
        <path
          d="M952 222C952.621 225.736 953.328 229.459 953.862 233.208 954.172 235.388 954.363 237.588 954.793 239.747 954.986 240.712 955.486 241.594 955.724 242.549 956.108 244.089 956.239 245.687 956.655 247.219 957.172 249.119 958.041 250.913 958.517 252.823 958.828 254.068 959.097 255.325 959.448 256.559 959.718 257.506 960.166 258.4 960.379 259.361 960.789 261.21 960.901 263.117 961.31 264.965 961.523 265.926 962.003 266.812 962.241 267.767 962.625 269.308 962.756 270.906 963.172 272.438 963.689 274.337 965.035 278.042 965.035 278.042 965.345 279.91 965.556 281.797 965.965 283.646 966.178 284.607 966.704 285.483 966.897 286.448 967.327 288.607 967.397 290.827 967.828 292.986 968.02 293.952 968.489 294.842 968.759 295.788 969.682 299.029 971.357 307.109 971.552 308.865 972 312.912 972.485 318.682 973.414 322.875 973.627 323.836 974.034 324.743 974.345 325.677 974.655 329.413 974.885 333.157 975.276 336.885 975.506 339.075 975.989 341.233 976.207 343.424 976.609 347.463 976.754 351.525 977.138 355.566 977.375 358.065 977.852 360.538 978.069 363.038 978.473 367.701 978.69 372.379 979 377.049 978.69 396.041 978.602 415.038 978.069 434.024 977.981 437.152 977.377 440.245 977.138 443.365 976.756 448.341 976.728 453.345 976.207 458.309 976.104 459.288 975.514 460.156 975.276 461.111 974.892 462.651 974.729 464.241 974.345 465.781 972.57 472.902 974.224 463.585 972.483 472.319 972.113 474.177 971.961 476.075 971.552 477.924 971.339 478.885 970.834 479.765 970.621 480.726 970.211 482.574 970.099 484.481 969.69 486.33 969.229 488.407 968.709 489.231 967.828 491"
          stroke={color}
          strokeWidth="40"
          strokeMiterlimit="8"
          fill="none"
          fillRule="evenodd"
        />
        <path
          d="M975 358.286C976.558 358 978.132 357.782 979.673 357.429 983.762 356.491 981.867 356.135 987.149 355.714 992.744 355.268 998.369 355.224 1003.97 354.857 1007.09 354.653 1010.2 354.286 1013.32 354 1036.35 354.306 1064.26 353.325 1089.01 355.714 1091.2 355.926 1093.36 356.36 1095.55 356.571 1099.28 356.932 1103.03 357.117 1106.77 357.429L1116.11 358.286 1124.52 359.143 1132 360"
          stroke={color}
          strokeWidth="40"
          strokeMiterlimit="8"
          fill="none"
          fillRule="evenodd"
        />
        <path
          d="M1134.52 221C1134.21 222.557 1134.01 224.139 1133.6 225.671 1133.09 227.571 1131.76 231.277 1131.76 231.277 1129.31 251.179 1130.23 241.213 1129 261.173 1129.31 287.955 1129.37 314.741 1129.92 341.519 1129.98 344.648 1130.65 347.738 1130.84 350.862 1131.26 357.706 1131.34 364.57 1131.76 371.415 1131.95 374.539 1132.43 377.638 1132.68 380.758 1133.05 385.424 1133.14 390.113 1133.6 394.772 1133.76 396.352 1134.33 397.866 1134.52 399.443 1137.51 424.775 1134.9 410.705 1137.28 422.799 1137.59 426.536 1137.59 430.311 1138.2 434.01 1138.52 435.953 1140.04 439.616 1140.04 439.616 1140.35 442.107 1140.52 444.62 1140.96 447.09 1141.13 448.059 1141.64 448.937 1141.88 449.893 1142.26 451.433 1142.39 453.032 1142.8 454.564 1143.31 456.464 1144.03 458.301 1144.64 460.17L1145.56 462.972 1146.48 465.775C1146.79 467.955 1146.91 470.169 1147.4 472.315 1147.84 474.234 1148.86 475.989 1149.24 477.92 1149.55 479.477 1149.78 481.051 1150.16 482.592 1150.39 483.547 1150.84 484.439 1151.08 485.394 1152.06 489.376 1152 488.673 1152 491"
          stroke={color}
          strokeWidth="40"
          strokeMiterlimit="8"
          fill="none"
          fillRule="evenodd"
        />
      </g>
    </svg>
  )
}

LetterH.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
  bgColor: PropTypes.string,
}

LetterH.defaultProps = {
  size: `100px`,
  color: `#fff`,
  bgColor: `#000`,
}

export default LetterH
