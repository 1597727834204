import React from "react"
import PropTypes from "prop-types"

const LetterI = ({ size, color, bgColor }) => {
  return (
    <svg
      width={size}
      height={size}
      aria-hidden="true"
      viewBox="-23 0 448 416.04455"
    >
      <defs>
        <clipPath id="letterI">
          <path
            d="M29 156 431 156 431 558 29 558Z"
            fillRule="evenodd"
            clipRule="evenodd"
          />
        </clipPath>
      </defs>
      <g clipPath="url(#letterI)" transform="translate(-29 -156)">
        <path
          d="M29 356.5C29 245.767 118.767 156 229.5 156 340.233 156 430 245.767 430 356.5 430 467.233 340.233 557 229.5 557 118.767 557 29 467.233 29 356.5Z"
          fill={bgColor}
          fillRule="evenodd"
        />
        <path
          d="M180 220C186.78 219.667 193.568 219.488 200.34 219 202.819 218.821 205.255 218.145 207.736 218 216.665 217.478 225.61 217.333 234.547 217 238.554 216.667 242.552 216.202 246.566 216 267.325 214.956 266.145 215 278 215"
          stroke={color}
          strokeWidth="40"
          strokeMiterlimit="8"
          fill="none"
          fillRule="evenodd"
        />
        <path
          d="M227 220C230.832 270.562 227.355 221.074 228.833 345.592 229.312 385.91 226.532 373.463 230.667 390.247 232.209 413.721 231.205 396.586 232.5 427.46 232.786 434.283 232.92 441.116 233.417 447.927 233.532 449.504 234.096 451.016 234.333 452.578 234.708 455.049 235.036 457.53 235.25 460.021 235.648 464.665 235.71 469.337 236.167 473.976 236.322 475.549 236.846 477.064 237.083 478.627 238.038 484.924 238 483.75 238 487"
          stroke={color}
          strokeWidth="40"
          strokeMiterlimit="8"
          fill="none"
          fillRule="evenodd"
        />
        <path
          d="M189 487C198.015 486.408 200.312 486.634 207.641 485.286 208.897 485.055 210.118 484.684 211.369 484.429 213.58 483.977 219.599 482.921 221.621 482.714 228.135 482.049 241.194 481 241.194 481 244.922 481.286 248.672 481.392 252.379 481.857 255.518 482.251 258.693 482.65 261.699 483.571 262.631 483.857 263.523 484.301 264.495 484.429 273.186 485.57 276.261 485.286 285 485.286"
          stroke={color}
          strokeWidth="40"
          strokeMiterlimit="8"
          fill="none"
          fillRule="evenodd"
        />
      </g>
    </svg>
  )
}

LetterI.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
  bgColor: PropTypes.string,
}

LetterI.defaultProps = {
  size: `100px`,
  color: `#fff`,
  bgColor: `#000`,
}

export default LetterI
