import React from "react"
import PropTypes from "prop-types"

const LetterT = ({ size, color, bgColor }) => {
  return (
    <svg
      width={size}
      height={size}
      aria-hidden="true"
      viewBox="-23 0 448 416.04455"
    >
      <defs>
        <clipPath id="letterT">
          <path
            d="M29 156 431 156 431 558 29 558Z"
            fillRule="evenodd"
            clipRule="evenodd"
          />
        </clipPath>
      </defs>
      <g clipPath="url(#letterT)" transform="translate(-29 -156)">
        <path
          d="M29 356.5C29 245.767 118.767 156 229.5 156 340.233 156 430 245.767 430 356.5 430 467.233 340.233 557 229.5 557 118.767 557 29 467.233 29 356.5Z"
          fill={bgColor}
          fillRule="evenodd"
        />
        <path
          d="M225 246C225.332 252.525 225.574 259.055 225.996 265.575 226.238 269.31 226.707 273.029 226.992 276.761 227.371 281.729 227.656 286.704 227.988 291.675 230.345 456.041 229.98 384.264 229.98 507"
          stroke={color}
          strokeWidth="40"
          strokeMiterlimit="8"
          fill="none"
          fillRule="evenodd"
        />
        <path
          d="M120 244C127.365 244.989 133.723 246 141.4 246 161.562 246 181.718 245.236 201.878 245L334 244"
          stroke={color}
          strokeWidth="40"
          strokeMiterlimit="8"
          fill="none"
          fillRule="evenodd"
        />
      </g>
    </svg>
  )
}

LetterT.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
  bgColor: PropTypes.string,
}

LetterT.defaultProps = {
  size: `100px`,
  color: `#fff`,
  bgColor: `#000`,
}

export default LetterT
