import { createSlice } from "@reduxjs/toolkit"

import storage from "../storage"

const soundSlice = createSlice({
  name: `sound`,
  initialState: {
    isMute: false,
    volume: 50,
    soundName: ``,
  },
  reducers: {
    muteSound: (state, action) => {
      const { payload } = action
      const bool = payload || false

      state.isMute = bool
    },
    setVolume: (state, action) => {
      const { payload } = action
      const volume = payload || 0.5

      state.volume = volume
    },
    playSound: (state, action) => {
      const { payload } = action
      const name = payload || ``

      state.soundName = name
    },
  },
})

const soundReducer = soundSlice.reducer
const soundPersistConfig = {
  key: `basuke436_sound`,
  storage: storage,
  whitelist: [`isMute`, `volume`],
}

export const { muteSound, setVolume, playSound } = soundSlice.actions
export { soundReducer, soundPersistConfig }
