import React, { useState, useEffect, useCallback } from "react"
import { navigate } from "gatsby"
import { useSelector, useDispatch } from "react-redux"
import styled from "styled-components"
import {
  // Button,
  IconButton,
  // MenuItem,
  Divider,
  Zoom,
  Fab,
  Slider,
  Fade,
} from "@mui/material"
import {
  // MdLanguage,
  MdColorLens,
  // MdKeyboardArrowDown,
  MdKeyboardArrowUp,
  MdKeyboardBackspace,
} from "react-icons/md"
import { FiVolume, FiVolume1, FiVolume2, FiVolumeX } from "react-icons/fi"
// import { GiCheckMark } from "react-icons/gi"

import HeaderSiteColor from "./header-site-color"
import { StyledPopover } from "./styles"
import {
  LetterA,
  LetterB,
  LetterE,
  LetterK,
  LetterS,
  LetterU,
} from "./icons/letters"
import getSiteColor from "../functions/get-site-color"
import header from "../static/header"
import { setCurrentTopic } from "../states/slices/main-slice"
import { muteSound, playSound, setVolume } from "../states/slices/sound-slice"

const HeaderContainer = styled.header`
  width: 100%;
  display: flex;
  z-index: 1100;
  box-sizing: border-box;
  flex-shrink: 0;
  flex-direction: column;
  position: fixed;
  top: -56px;
  left: auto;
  right: 0;
`

const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const HeaderContent = styled.span`
  position: relative;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  padding: 0 0.75rem;
  height: 56px;
  margin-top: 0.5rem;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);

  &.menu {
    transition: background-color 0.3s, color 0.3s, left 0.4s ease 0.25s;
    border-radius: 100%;
    position: fixed;
    left: 0;
    height: auto;
    padding: 0;
    margin-top: 1rem;
    margin-left: 0.75rem;
  }

  &.main {
    transition: background-color 0.3s, color 0.3s, left 0.4s ease 0.25s;
    border-radius: 100px;
  }

  @media (max-width: 550px) {
    &.menu {
      padding: 0;
      margin: 0 0.75rem;
      height: auto;
      border-radius: 100%;
      top: 70px;
    }

    &.main {
      // width: 100%;
      margin-top: 0.5rem;
      margin-left: 0.75rem;
      margin-right: 0.75rem;
    }
  }
`

const ControlPanel = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .menu-opening {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
`

const LogoBlock = styled.div`
  display: flex;
  align-items: flex-end;
  margin-left: 0.5rem;
  padding: 6px;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 50px;
  transition: 0.3s;

  svg {
    path {
      transition: 0.3s;
    }
  }
`

const maxWidthHidden = 300
const HiddenLetter = styled.span`
  display: flex;

  ${({ forceDisplay }) =>
    forceDisplay !== `always` &&
    `
      @media (max-width: ${maxWidthHidden}px) {
          display: none;
      }
    `}
`

// const CurrentLang = styled.span`
//   padding: 0 0.25rem;
// `

const Header = () => {
  const { lang, theme, siteColor, currentPage, currentTopic, pageCount } =
    useSelector(({ mainReducer }) => mainReducer)
  const { isMute, volume } = useSelector(({ soundReducer }) => soundReducer)
  const dispatch = useDispatch()
  // const [popoverLangAnchorEl, setPopoverLangAnchorEl] = useState(null)
  const [colorSelectionAnchorEl, setColorSelectionAnchorEl] = useState(null)
  const [volumeAnchorEl, setVolumeAnchorEl] = useState(null)
  // const [prevScrollPos, setPrevScrollPos] = useState(0)
  const [appbarVisible, setAppbarVisible] = useState(false)
  const [backToTopButtonVisible, setBackToTopButtonVisible] = useState(false)

  const appbarFontColor =
    theme === `light`
      ? { color: `#000`, backgroundColor: `rgb(255, 255, 255)` }
      : { color: `#fff`, backgroundColor: `rgb(50, 50, 50)` }
  const appbarTransform = appbarVisible ? { left: `0` } : { left: `-75px` }

  const handleScroll = useCallback(() => {
    const currentScrollPos = window.pageYOffset

    // if (currentScrollPos > prevScrollPos && currentScrollPos >= 200) {
    //   // downscroll
    //   setAppbarVisible(false)
    // } else {
    //   // upscroll
    //   setAppbarVisible(true)
    // }

    if (currentScrollPos >= 500) {
      setBackToTopButtonVisible(true)
    } else {
      setBackToTopButtonVisible(false)
    }

    // setPrevScrollPos(currentScrollPos)
  }, [])

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)

    return () => window.removeEventListener("scroll", handleScroll)
  }, [handleScroll])

  useEffect(() => {
    if (currentPage !== `index`) {
      setAppbarVisible(true)
    } else {
      setAppbarVisible(false)
    }
  }, [currentPage])

  useEffect(() => {
    if (currentTopic !== ``) {
      setTimeout(() => {
        dispatch(setCurrentTopic(``))
      }, 1500)
    }
  }, [currentTopic, dispatch])

  // const openLangMenu = getEvent => {
  //   setPopoverLangAnchorEl(getEvent.currentTarget)
  // }

  // const closeLangMenu = () => {
  //   setPopoverLangAnchorEl(null)
  // }

  const goBackToTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }

  const displayLogo = option => {
    return (
      <LogoBlock theme={theme}>
        <LetterB
          size="2rem"
          bgColor={getSiteColor(siteColor, theme)}
          color={theme === `light` ? `#fff` : `#000`}
        />
        <HiddenLetter
          forceDisplay={option === `ignore-hidden` ? `always` : `never`}
        >
          <LetterA
            size="1rem"
            bgColor="none"
            color={theme === `light` ? `#000` : `#fff`}
          />
          <LetterS
            size="1rem"
            bgColor="none"
            color={theme === `light` ? `#000` : `#fff`}
          />
          <LetterU
            size="1rem"
            bgColor="none"
            color={theme === `light` ? `#000` : `#fff`}
          />
          <LetterK
            size="1rem"
            bgColor="none"
            color={theme === `light` ? `#000` : `#fff`}
          />
          <LetterE
            size="1rem"
            bgColor="none"
            color={theme === `light` ? `#000` : `#fff`}
          />
        </HiddenLetter>
      </LogoBlock>
    )
  }

  const renderVolumeIcon = () => {
    if (isMute) {
      return <FiVolumeX />
    }

    if (volume > 50) {
      return <FiVolume2 />
    }

    if (volume > 0) {
      return <FiVolume1 />
    }

    return <FiVolume />
  }

  return (
    <>
      <HeaderContainer>
        <HeaderRow>
          <HeaderContent
            style={{
              ...appbarFontColor,
              ...appbarTransform,
            }}
            className="menu animate__animated animate__fadeInLeft"
          >
            <ControlPanel>
              <div className="menu-opening">
                <IconButton
                  aria-label="back-to-home"
                  color="inherit"
                  onClick={() => {
                    dispatch(playSound(`cancel`))

                    if (pageCount > 1) {
                      navigate(-1)
                    } else {
                      navigate(`/`)
                    }
                  }}
                  disabled={currentPage === `index`}
                >
                  <MdKeyboardBackspace />
                </IconButton>
                {/* {displayLogo()} */}
              </div>
            </ControlPanel>
          </HeaderContent>
          <HeaderContent
            style={{
              // ...appbarTransform,
              ...appbarFontColor,
              top: 56,
            }}
            className="main animate__animated animate__fadeInDown"
          >
            {displayLogo()}
            <Divider orientation="vertical" flexItem style={{ margin: 8 }} />
            <ControlPanel theme={theme}>
              {/* <Button
                style={{
                  transition: `color 0s`,
                }}
                aria-label="open-language-menu"
                onClick={event => {
                  openLangMenu(event)
                  dispatch(playSound(`confirm`))
                }}
                color="inherit"
              >
                <MdLanguage style={{ fontSize: 24 }} />
                <CurrentLang>
                  {header.languageSelection.button[lang]}
                </CurrentLang>
                {Boolean(popoverLangAnchorEl) === true ? (
                  <MdKeyboardArrowUp style={{ fontSize: 20 }} />
                ) : (
                  <MdKeyboardArrowDown style={{ fontSize: 20 }} />
                )}
              </Button> */}
              {/* <StyledPopover
                anchorEl={popoverLangAnchorEl}
                keepMounted
                open={Boolean(popoverLangAnchorEl)}
                onClose={() => {
                  closeLangMenu()
                  dispatch(playSound(`cancel`))
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                TransitionComponent={Fade}
              >
                <div>
                  {header.languageSelection.languages.map(
                    (language, languageIndex) => {
                      return (
                        <MenuItem
                          key={`lang_choice_${languageIndex}`}
                          onClick={() => {
                            dispatch(setLanguage(language.code))
                            closeLangMenu()
                            dispatch(playSound(`confirm`))
                          }}
                          selected={lang === language.code}
                        >
                          <GiCheckMark
                            style={{
                              opacity: lang === language.code ? 1 : 0,
                              color: theme === `light` ? `#000` : `#fff`,
                            }}
                          />
                          {language.display}
                        </MenuItem>
                      )
                    }
                  )}
                </div>
              </StyledPopover> */}
              <IconButton
                aria-label="open-color-menu"
                onClick={e => {
                  setColorSelectionAnchorEl(e.currentTarget)
                  dispatch(playSound(`confirm`))
                }}
                color="inherit"
              >
                <MdColorLens />
              </IconButton>
              <HeaderSiteColor
                anchorEl={colorSelectionAnchorEl}
                callback={() => {
                  setColorSelectionAnchorEl(null)
                  dispatch(playSound(`cancel`))
                }}
              />
              <IconButton
                aria-label="open-volume-menu"
                onClick={e => {
                  setVolumeAnchorEl(e.currentTarget)
                  dispatch(playSound(`confirm`))
                }}
                color="inherit"
              >
                {renderVolumeIcon()}
              </IconButton>
              <StyledPopover
                anchorEl={volumeAnchorEl}
                keepMounted
                open={Boolean(volumeAnchorEl)}
                onClose={() => {
                  setVolumeAnchorEl(null)
                  dispatch(playSound(`cancel`))
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                TransitionComponent={Fade}
              >
                <div className="popover__title">{header.volume[lang]}</div>
                <Divider />
                <div
                  style={{
                    padding: `12px 16px`,
                    display: `flex`,
                    justifyContent: `center`,
                    alignItems: `center`,
                  }}
                >
                  <IconButton
                    size="small"
                    onClick={() => {
                      dispatch(playSound(`click`))
                      dispatch(muteSound(!isMute))
                    }}
                  >
                    {renderVolumeIcon()}
                  </IconButton>
                  <Slider
                    size="small"
                    style={{ marginLeft: 12 }}
                    aria-labelledby="volume-slider"
                    valueLabelDisplay="auto"
                    step={1}
                    min={0}
                    max={100}
                    value={volume}
                    onChange={(_, newValue) => {
                      if (isMute) {
                        dispatch(muteSound(false))
                      }
                      dispatch(setVolume(newValue))
                    }}
                    onChangeCommitted={() => {
                      dispatch(playSound(`click`))
                    }}
                  />
                </div>
              </StyledPopover>
            </ControlPanel>
          </HeaderContent>
          <div />
        </HeaderRow>
      </HeaderContainer>
      <Zoom in={backToTopButtonVisible}>
        <div
          style={{
            position: `fixed`,
            bottom: 40,
            right: 30,
            zIndex: 1,
          }}
        >
          <Fab
            aria-label="back to top"
            color="primary"
            style={{
              width: 45,
              height: 45,
              transition: `color 0.3s, background-color 0.3s`,
            }}
            onClick={() => {
              goBackToTop()
              dispatch(playSound(`confirm`))
            }}
          >
            <MdKeyboardArrowUp style={{ fontSize: 20 }} />
          </Fab>
        </div>
      </Zoom>
    </>
  )
}

export default Header
