import React from "react"
import PropTypes from "prop-types"

const LetterJ = ({ size, color, bgColor }) => {
  return (
    <svg
      width={size}
      height={size}
      aria-hidden="true"
      viewBox="-23 0 448 416.04455"
    >
      <defs>
        <clipPath id="letterJ">
          <path
            d="M438 156 840 156 840 558 438 558Z"
            fillRule="evenodd"
            clipRule="evenodd"
          />
        </clipPath>
      </defs>
      <g clipPath="url(#letterJ)" transform="translate(-438 -156)">
        <path
          d="M438 356.5C438 245.767 527.767 156 638.5 156 749.233 156 839 245.767 839 356.5 839 467.233 749.233 557 638.5 557 527.767 557 438 467.233 438 356.5Z"
          fill={bgColor}
          fillRule="evenodd"
        />
        <path
          d="M586 214.003 615.096 216.001 630.114 217 662.026 216.001C664.856 215.861 667.648 215.225 670.474 215.002 684.551 213.892 683.29 214.003 693 214.003"
          stroke={color}
          strokeWidth="40"
          strokeMiterlimit="8"
          fill="none"
          fillRule="evenodd"
        />
        <path
          d="M638.648 218C638.958 219.866 639.121 221.762 639.579 223.597 640.055 225.505 641.442 229.195 641.442 229.195 642.063 234.17 642.921 239.121 643.305 244.121 643.615 248.163 643.812 252.216 644.236 256.248 644.434 258.129 644.947 259.967 645.167 261.846 645.568 265.257 645.614 268.707 646.099 272.107 646.237 273.081 646.817 273.946 647.03 274.906 647.44 276.753 647.651 278.638 647.961 280.503 648.272 286.412 648.422 292.331 648.893 298.228 649.043 300.114 649.636 301.943 649.824 303.825 650.258 308.168 650.361 312.539 650.755 316.886 651.222 322.024 651.803 325.046 652.618 329.946 652.928 334.3 653.172 338.659 653.549 343.007 653.793 345.812 654.265 348.595 654.481 351.403 654.886 356.683 654.933 361.988 655.412 367.262 655.581 369.122 656.779 373.672 657.275 375.658 657.585 382.81 657.76 389.969 658.206 397.114 658.381 399.924 658.93 402.702 659.137 405.51 662.277 447.973 658.325 403.092 661 432.564 660.69 442.826 660.568 453.095 660.069 463.349 659.661 471.726 659.676 468.458 658.206 473.611 655.391 483.48 659.839 469.637 655.412 482.94L654.481 485.738C654.17 486.671 654.366 487.991 653.549 488.537 652.618 489.159 651.629 489.702 650.755 490.403 650.07 490.952 649.441 491.582 648.893 492.269 648.193 493.144 647.904 494.367 647.03 495.067 646.263 495.681 645.167 495.689 644.236 496 633.681 495.689 623.115 495.638 612.57 495.067 611.59 495.014 610.72 494.404 609.776 494.134 607.499 493.482 602.123 492.444 600.463 491.336 599.532 490.714 598.692 489.925 597.669 489.47 592.216 487.042 591.139 488.527 586.493 483.873 585.872 483.251 585.179 482.694 584.63 482.007 583.931 481.131 583.559 480.001 582.768 479.208 581.976 478.415 580.905 477.964 579.974 477.342 579.072 474.634 579.149 474.047 577.18 471.745 576.037 470.409 574.428 469.477 573.454 468.013 568.116 459.993 574.516 470.141 570.66 462.416 570.16 461.413 569.298 460.62 568.798 459.617 568.359 458.738 568.305 457.698 567.866 456.819 565.831 452.742 566.004 455.424 566.004 453.087"
          stroke={color}
          strokeWidth="40"
          strokeMiterlimit="8"
          fill="none"
          fillRule="evenodd"
        />
      </g>
    </svg>
  )
}

LetterJ.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
  bgColor: PropTypes.string,
}

LetterJ.defaultProps = {
  size: `100px`,
  color: `#fff`,
  bgColor: `#000`,
}

export default LetterJ
