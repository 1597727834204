import { configureStore } from "@reduxjs/toolkit"
import { persistStore, persistReducer } from "redux-persist"

import { mainReducer, mainPersistConfig } from "./slices/main-slice"
import { clockReducer, clockPersistConfig } from "./slices/clock-slice"
import { soundReducer, soundPersistConfig } from "./slices/sound-slice"

const rootReducer = {
  mainReducer: persistReducer(mainPersistConfig, mainReducer),
  clockReducer: persistReducer(clockPersistConfig, clockReducer),
  soundReducer: persistReducer(soundPersistConfig, soundReducer),
}

const myStore = () => {
  const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({ serializableCheck: false }),
  })
  const persistor = persistStore(store)
  return { store, persistor }
}

export default myStore
