import { createSlice } from "@reduxjs/toolkit"

import storage from "../storage"

const multipliers = 7
const getUniversalCoordinatedTime = () => {
  const dateString = new Date()
  dateString.setTime(dateString.valueOf() + multipliers * 60 * 60 * 1000)

  return {
    day: dateString.getUTCDay(), // 0 - 7 ---> Sun - Sat
    date: dateString.getUTCDate(),
    month: dateString.getUTCMonth(), // 0 - 11 --> Jan - Dec
    year: dateString.getUTCFullYear(),
    hour: dateString.getUTCHours(),
    minute: dateString.getUTCMinutes(),
    second: dateString.getUTCSeconds(),
  }
}

const clockSlice = createSlice({
  name: `clock`,
  initialState: {
    utcMultipliers: multipliers,
    newDate: getUniversalCoordinatedTime(),
    timeFormat: {
      twentyFourHourTime: false,
      longDate: false,
    },
    clockModalVisibility: false,
  },
  reducers: {
    setNewDate: state => {
      state.newDate = getUniversalCoordinatedTime()
    },
    setTimeFormat: (state, action) => {
      const { payload } = action
      const twentyFourHourTime = payload.twentyFourHourTime
      const longDate = payload.longDate

      state.timeFormat.twentyFourHourTime = twentyFourHourTime
      state.timeFormat.longDate = longDate
    },
    setClockModalVisibility: (state, action) => {
      const { payload } = action
      const bool = !!payload || false

      state.clockModalVisibility = bool
    },
  },
})

const clockReducer = clockSlice.reducer
const clockPersistConfig = {
  key: `basuke436_clock`,
  storage: storage,
  whitelist: [`utcMultipliers`, `timeFormat`],
}

export const { setNewDate, setTimeFormat, setClockModalVisibility } =
  clockSlice.actions
export { clockReducer, clockPersistConfig }
