import React from "react"
import PropTypes from "prop-types"

const LetterL = ({ size, color, bgColor }) => {
  return (
    <svg
      width={size}
      height={size}
      aria-hidden="true"
      viewBox="-23 0 448 416.04455"
    >
      <defs>
        <clipPath id="letterL">
          <path
            d="M29 156 431 156 431 558 29 558Z"
            fillRule="evenodd"
            clipRule="evenodd"
          />
        </clipPath>
      </defs>
      <g clipPath="url(#letterL)" transform="translate(-29 -156)">
        <path
          d="M29 356.5C29 245.767 118.767 156 229.5 156 340.233 156 430 245.767 430 356.5 430 467.233 340.233 557 229.5 557 118.767 557 29 467.233 29 356.5Z"
          fill={bgColor}
          fillRule="evenodd"
        />
        <path
          d="M159 207C159.314 208.553 159.553 210.123 159.941 211.659 160.182 212.612 160.642 213.502 160.882 214.455 161.27 215.991 161.477 217.567 161.824 219.114 162.104 220.364 162.533 221.581 162.765 222.841 163.162 225.002 163.271 227.21 163.706 229.364 164.213 231.875 165.588 236.818 165.588 236.818 165.902 239.303 166.114 241.803 166.529 244.273 166.742 245.536 167.239 246.74 167.471 248 167.867 250.161 167.977 252.369 168.412 254.523 168.919 257.034 170.294 261.977 170.294 261.977 170.639 265.046 172.001 276.91 172.176 279.682 172.607 286.509 172.745 293.351 173.118 300.182 173.372 304.844 173.765 309.499 174.059 314.159 174.392 319.438 174.686 324.72 175 330 174.686 353.917 174.614 377.838 174.059 401.75 173.986 404.871 173.308 407.952 173.118 411.068 171.13 443.533 173.337 422.159 171.235 440.886 170.761 450.282 170.606 459.536 169.353 468.841 169.142 470.411 168.726 471.947 168.412 473.5 167.768 480.507 168.156 482.109 166.529 487.477 164.796 493.199 165.986 491.743 163.706 494"
          stroke={color}
          strokeWidth="40"
          strokeMiterlimit="8"
          fill="none"
          fillRule="evenodd"
        />
        <path
          d="M153 485C154.559 485.296 156.142 485.491 157.676 485.889 167.937 488.549 159.137 486.889 167.027 488.555 173.639 489.952 170.441 489.096 178.249 490.333 179.816 490.582 181.349 491.022 182.924 491.222 197.225 493.035 213.198 492.649 226.876 493L276.438 492.111C278.333 492.05 280.161 491.393 282.049 491.222 286.714 490.8 291.4 490.63 296.076 490.333 297.323 490.037 298.58 489.78 299.816 489.444 300.764 489.187 301.655 488.739 302.622 488.555 304.783 488.145 306.993 488.011 309.168 487.667 310.735 487.418 312.292 487.105 313.843 486.778 315.098 486.513 316.302 485.983 317.584 485.889 320.381 485.684 323.195 485.889 326 485.889"
          stroke={color}
          strokeWidth="40"
          strokeMiterlimit="8"
          fill="none"
          fillRule="evenodd"
        />
      </g>
    </svg>
  )
}

LetterL.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
  bgColor: PropTypes.string,
}

LetterL.defaultProps = {
  size: `100px`,
  color: `#fff`,
  bgColor: `#000`,
}

export default LetterL
