import { createSlice } from "@reduxjs/toolkit"

import storage from "../storage"

const mainSlice = createSlice({
  name: `main`,
  initialState: {
    isSystemTheme: false,
    theme: `light`,
    siteColor: `teal`,
    lang: `en`,
    currentTopic: ``,
    currentPage: ``,
    pageGroup: ``,
    pageCount: 0,
  },
  reducers: {
    setIsSystemTheme: (state, action) => {
      state.isSystemTheme = action.payload
    },
    setTheme: (state, action) => {
      state.theme = action.payload
    },
    setSiteColor: (state, action) => {
      state.siteColor = action.payload
    },
    setLanguage: (state, action) => {
      state.lang = action.payload
    },
    setCurrentTopic: (state, action) => {
      state.currentTopic = action.payload
    },
    setCurrentPage: (state, action) => {
      const { payload } = action
      const page = payload.page || ``
      const group = payload.group || ``

      state.currentPage = page
      state.pageGroup = group
      state.pageCount = state.pageCount + 1
    },
    increasePageCount: state => {
      state.pageCount = state.pageCount + 1
    },
  },
})

const mainReducer = mainSlice.reducer
const mainPersistConfig = {
  key: `basuke436_main`,
  storage: storage,
  whitelist: [`isSystemTheme`, `theme`, `lang`],
}

export const {
  setIsSystemTheme,
  setTheme,
  setSiteColor,
  setLanguage,
  setCurrentTopic,
  setCurrentPage,
  increasePageCount,
} = mainSlice.actions
export { mainReducer, mainPersistConfig }
