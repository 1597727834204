import React from "react"
import PropTypes from "prop-types"
import { useSelector, useDispatch } from "react-redux"
import styled from "styled-components"
import { Tooltip, Divider, Fade } from "@mui/material"
import { VscCheck } from "react-icons/vsc"
import { MdOutlineWbSunny, MdOutlineDarkMode, MdDevices } from "react-icons/md"

import { StyledPopover } from "./styles"
import getSiteColor from "../functions/get-site-color"
import hexToRgbaColor from "../functions/hex-to-rgba-color"
import colorsList from "../static/colors-list"
import header from "../static/header"
import {
  setIsSystemTheme,
  setTheme,
  setSiteColor,
} from "../states/slices/main-slice"
import { playSound } from "../states/slices/sound-slice"

const Flex = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  max-width: 13rem;
  padding: 0.5rem;
`

const Block = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  width: 2.5rem;
  cursor: pointer;
  border-radius: 100%;
  margin: 0.25rem;
  transition: border 0.3s;

  svg {
    font-size: 1.5rem;
    transition: color 0.3s;
  }

  &:hover {
    transition: border 0.1s;

    svg {
      transition: color 0.1s;
    }
  }
`

const HeaderSiteColor = ({ anchorEl, callback }) => {
  const { isSystemTheme, lang, theme, siteColor } = useSelector(
    ({ mainReducer }) => mainReducer
  )
  const dispatch = useDispatch()

  return (
    <StyledPopover
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={() => {
        callback()
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      TransitionComponent={Fade}
    >
      <div className="popover__title">{header.colorSelection[lang]}</div>
      <Divider />
      <Flex>
        {colorsList.map((color, index) => {
          return (
            <div key={`site_color_select_${index}`}>
              <Tooltip title={color[lang]} arrow placement="top">
                <Block
                  role="presentation"
                  style={{
                    backgroundColor: hexToRgbaColor(
                      getSiteColor(color.code, `light`),
                      1
                    ),
                    border:
                      siteColor === color.code
                        ? `2px solid ${
                            theme === `light`
                              ? `rgb(75, 75, 75)`
                              : `rgb(255, 255, 255)`
                          }`
                        : `2px solid ${hexToRgbaColor(
                            getSiteColor(color.code, `light`),
                            1
                          )}`,
                  }}
                  onClick={() => {
                    dispatch(setSiteColor(color.code))

                    if (siteColor !== color.code) {
                      dispatch(playSound(`confirm`))
                    }
                  }}
                >
                  <VscCheck
                    style={{
                      color: `rgba(${
                        theme === `light` ? `0, 0, 0` : `255, 255, 255`
                      }, ${siteColor === color.code ? `1` : `0`})`,
                    }}
                  />
                </Block>
              </Tooltip>
            </div>
          )
        })}
      </Flex>

      <div className="popover__title">{header.theme.title[lang]}</div>
      <Divider />
      <Flex>
        <Tooltip title={header.theme.system[lang]} arrow placement="top">
          <Block
            role="presentation"
            style={{
              height: `2.75rem`,
              width: `2.75rem`,
              backgroundColor: hexToRgbaColor(
                getSiteColor(
                  isSystemTheme ? siteColor : `grey`,
                  theme === `light` ? `dark` : `light`
                ),
                1
              ),
              transition: `background-color 0.3s`,
            }}
            onClick={() => {
              if (!isSystemTheme) {
                dispatch(playSound(`theme-switch`))
              }

              dispatch(setIsSystemTheme(true))
            }}
          >
            <MdDevices />
          </Block>
        </Tooltip>
        <Tooltip title={header.theme.light[lang]} arrow placement="top">
          <Block
            role="presentation"
            style={{
              height: `2.75rem`,
              width: `2.75rem`,
              backgroundColor: hexToRgbaColor(
                getSiteColor(
                  theme === `light` && !isSystemTheme ? siteColor : `grey`,
                  theme === `light` ? `dark` : `light`
                ),
                1
              ),
              transition: `background-color 0.3s`,
            }}
            onClick={() => {
              if (theme !== `light` || isSystemTheme) {
                dispatch(setIsSystemTheme(false))
                dispatch(setTheme(`light`))
                dispatch(playSound(`theme-switch`))
              }
            }}
          >
            <MdOutlineWbSunny />
          </Block>
        </Tooltip>
        <Tooltip title={header.theme.dark[lang]} arrow placement="top">
          <Block
            role="presentation"
            style={{
              height: `2.75rem`,
              width: `2.75rem`,
              backgroundColor: hexToRgbaColor(
                getSiteColor(
                  theme === `dark` && !isSystemTheme ? siteColor : `grey`,
                  theme === `light` ? `dark` : `light`
                ),
                1
              ),
              transition: `background-color 0.3s`,
            }}
            onClick={() => {
              if (theme !== `dark` || isSystemTheme) {
                dispatch(setIsSystemTheme(false))
                dispatch(setTheme(`dark`))
                dispatch(playSound(`theme-switch`))
              }
            }}
          >
            <MdOutlineDarkMode />
          </Block>
        </Tooltip>
      </Flex>
    </StyledPopover>
  )
}

HeaderSiteColor.propTypes = {
  anchorEl: PropTypes.any,
  callback: PropTypes.func,
}

HeaderSiteColor.defaultProps = {
  anchorEl: null,
  callback: () => {},
}

export default HeaderSiteColor
