import React from "react"
import PropTypes from "prop-types"
import { useSelector, useDispatch } from "react-redux"
import { ThemeProvider, createTheme } from "@mui/material/styles"
import styled, { createGlobalStyle } from "styled-components"

import Header from "./header"
import Footer from "./footer"
import Sound from "./sound"
import useThemeDetector from "../functions/use-theme-detector"
import hexToRgbaColor from "../functions/hex-to-rgba-color"
import getSiteColor from "../functions/get-site-color"
import { setTheme } from "../states/slices/main-slice"

const GlobalStyles = createGlobalStyle`
  body {
    &.active {
      display: block;
      background-color: ${({ theme, siteColor }) =>
        theme === `light`
          ? hexToRgbaColor(getSiteColor(siteColor, theme), 0.05)
          : `rgb(25, 25, 25)`};
      transition: background-color 0.3s;
    }
  }
`

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme, siteColor }) =>
    theme === `light`
      ? hexToRgbaColor(getSiteColor(siteColor, theme), 0.05)
      : `rgb(25, 25, 25)`};
  transition: background-color 0.3s;
`

const AppRow = styled.div`
  display: flex;
  flex-direction: row;
`

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  position: relative;
`

const Container = ({ children }) => {
  const { isSystemTheme, theme, siteColor } = useSelector(
    ({ mainReducer }) => mainReducer
  )
  const dispatch = useDispatch()
  const muiTheme = createTheme({
    palette: {
      mode: theme,
      primary: {
        main: getSiteColor(siteColor, theme),
      },
    },
    typography: {
      fontFamily: `var(--main-font-family)`,
    },
  })
  const isDarkTheme = useThemeDetector()

  React.useEffect(() => {
    if (isSystemTheme) {
      dispatch(setTheme(isDarkTheme ? `dark` : `light`))
    }
  }, [isSystemTheme, isDarkTheme, dispatch])

  React.useEffect(() => {
    document.body.className = `active`

    return () => {
      document.body.className = ``
    }
  }, [])

  return (
    <ThemeProvider theme={muiTheme}>
      <GlobalStyles theme={theme} siteColor={siteColor} />
      <AppContainer theme={theme} siteColor={siteColor}>
        <Header />
        <Sound />
        <AppRow>
          <MainContainer>
            {children}
            <Footer />
          </MainContainer>
        </AppRow>
      </AppContainer>
    </ThemeProvider>
  )
}

Container.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Container
