const colorsList = [
  {
    code: `red`,
    en: `Red`,
    th: `แดง`,
  },
  {
    code: `pink`,
    en: `Pink`,
    th: `ชมพู`,
  },
  {
    code: `purple`,
    en: `Purple`,
    th: `ม่วง`,
  },
  {
    code: `deepPurple`,
    en: `Deep Purple`,
    th: `ม่วงเข้ม`,
  },
  {
    code: `indigo`,
    en: `Indigo`,
    th: `คราม`,
  },
  {
    code: `blue`,
    en: `Blue`,
    th: `น้ำเงิน`,
  },
  // {
  //     code: `lightBlue`,
  //     en: `Light Blue,
  //     th: `น้ำเงินอ่อน`
  // },
  {
    code: `cyan`,
    en: `Cyan`,
    th: `เขียวน้ำเงิน`,
  },
  {
    code: `teal`,
    en: `Teal`,
    th: `เขียวนกเป็ดน้ำ`,
  },
  {
    code: `green`,
    en: `Green`,
    th: `เขียว`,
  },
  {
    code: `lightGreen`,
    en: `Light Green`,
    th: `เขียวอ่อน`,
  },
  {
    code: `lime`,
    en: `Lime`,
    th: `เขียวไลม์`,
  },
  // {
  //     code: `yellow`,
  //     en: `Yellow`,
  //     th: `เหลือง`
  // },
  {
    code: `amber`,
    en: `Amber`,
    th: `เหลืองอำพัน`,
  },
  {
    code: `orange`,
    en: `Orange`,
    th: `ส้ม`,
  },
  {
    code: `deepOrange`,
    en: `Deep Orange`,
    th: `ส้มเข้ม`,
  },
  {
    code: `brown`,
    en: `Brown`,
    th: `น้ำตาล`,
  },
  // {
  //     code: `grey`,
  //     en: `Grey`,
  //     th: `เทา`
  // },
  // {
  //     code: `blueGrey`,
  //     en: `Blue Grey`,
  //     th: `เทาน้ำเงิน`
  // },
]

export default colorsList
