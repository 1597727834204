import React, { useCallback, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import useSound from "use-sound"

import click from "../files/sounds/click.mp3"
import confirm from "../files/sounds/click.mp3"
import cancel from "../files/sounds/click-revert.mp3"
import themeSwitch from "../files/sounds/switch-theme.mp3"
import fftCharge from "../files/sounds/fft/charge.mp3"
import fftSuccess from "../files/sounds/fft/success.mp3"
import fftError from "../files/sounds/fft/error.mp3"
import fftTick from "../files/sounds/fft/tick.mp3"
import { playSound } from "../states/slices/sound-slice"

const Sound = () => {
  const { isMute, volume, soundName } = useSelector(
    ({ soundReducer }) => soundReducer
  )
  const dispatch = useDispatch()
  const currentVolume = !isMute ? (volume / 100).toFixed(2) : 0

  const [playClickSound] = useSound(click, {
    volume: currentVolume,
  })
  const [playConfirmSound] = useSound(confirm, {
    volume: currentVolume,
  })
  const [playCancelSound] = useSound(cancel, {
    volume: currentVolume,
  })
  const [playThemeSwitchSound] = useSound(themeSwitch, {
    volume: currentVolume,
  })
  const [playFftChargeSound] = useSound(fftCharge, {
    volume: currentVolume,
  })
  const [playFftSucessSound] = useSound(fftSuccess, {
    volume: currentVolume,
  })
  const [playFftErrorSound] = useSound(fftError, {
    volume: currentVolume,
  })
  const [playFftTickSound] = useSound(fftTick, {
    volume: currentVolume,
  })

  const playThisSound = useCallback(() => {
    switch (soundName) {
      case `click`:
        playClickSound()
        break

      case `confirm`:
        playConfirmSound()
        break

      case `cancel`:
        playCancelSound()
        break

      case `theme-switch`:
        playThemeSwitchSound()
        break

      case `fftCharge`:
        playFftChargeSound()
        break

      case `fftSuccess`:
        playFftSucessSound()
        break

      case `fftError`:
        playFftErrorSound()
        break

      case `fftTick`:
        playFftTickSound()
        break

      default:
        playClickSound()
        break
    }
  }, [
    soundName,
    playClickSound,
    playConfirmSound,
    playCancelSound,
    playThemeSwitchSound,
    playFftChargeSound,
    playFftSucessSound,
    playFftErrorSound,
    playFftTickSound,
  ])

  useEffect(() => {
    if (soundName !== ``) {
      playThisSound(soundName)

      dispatch(playSound(``))
    }
  }, [soundName, playThisSound, dispatch])

  // useEffect(() => {
  //   dispatch(muteSound(false))
  // }, [volume, dispatch])

  return <></>
}

export default Sound
