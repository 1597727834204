import {
  red,
  pink,
  purple,
  deepPurple,
  indigo,
  blue,
  lightBlue,
  cyan,
  teal,
  green,
  lightGreen,
  lime,
  yellow,
  amber,
  orange,
  deepOrange,
  brown,
  grey,
  blueGrey,
} from "@mui/material/colors"

const lightShade = 700
const darkShade = 200

const getSiteColor = (colorString, currentTheme) => {
  switch (colorString) {
    case "red":
      return currentTheme === "light" ? red[lightShade] : red[darkShade]

    case "pink":
      return currentTheme === "light" ? pink[lightShade] : pink[darkShade]

    case "purple":
      return currentTheme === "light" ? purple[lightShade] : purple[darkShade]

    case "deepPurple":
      return currentTheme === "light"
        ? deepPurple[lightShade]
        : deepPurple[darkShade]

    case "indigo":
      return currentTheme === "light" ? indigo[lightShade] : indigo[darkShade]

    case "blue":
      return currentTheme === "light" ? blue[lightShade] : blue[darkShade]

    case "lightBlue":
      return currentTheme === "light"
        ? lightBlue[lightShade]
        : lightBlue[darkShade]

    case "cyan":
      return currentTheme === "light" ? cyan[lightShade] : cyan[darkShade]

    case "teal":
      return currentTheme === "light" ? teal[lightShade] : teal[darkShade]

    case "green":
      return currentTheme === "light" ? green[lightShade] : green[darkShade]

    case "lightGreen":
      return currentTheme === "light"
        ? lightGreen[lightShade]
        : lightGreen[darkShade]

    case "lime":
      return currentTheme === "light" ? lime[lightShade] : lime[darkShade]

    case "yellow":
      return currentTheme === "light" ? yellow[lightShade] : yellow[darkShade]

    case "amber":
      return currentTheme === "light" ? amber[lightShade] : amber[darkShade]

    case "orange":
      return currentTheme === "light" ? orange[lightShade] : orange[darkShade]

    case "deepOrange":
      return currentTheme === "light"
        ? deepOrange[lightShade]
        : deepOrange[darkShade]

    case "brown":
      return currentTheme === "light" ? brown[lightShade] : brown[darkShade]

    case "grey":
      return currentTheme === "light" ? grey[lightShade] : grey[darkShade]

    case "blueGrey":
      return currentTheme === "light"
        ? blueGrey[lightShade]
        : blueGrey[darkShade]

    default:
      return currentTheme === "light" ? blue[lightShade] : blue[darkShade] // blue as default
  }
}

export default getSiteColor
