import React from "react"
import PropTypes from "prop-types"

const LetterK = ({ size, color, bgColor }) => {
  return (
    <svg
      width={size}
      height={size}
      aria-hidden="true"
      viewBox="-23 0 448 416.04455"
    >
      <defs>
        <clipPath id="letterK">
          <path
            d="M847 156 1249 156 1249 558 847 558Z"
            fillRule="evenodd"
            clipRule="evenodd"
          />
        </clipPath>
      </defs>
      <g clipPath="url(#letterK)" transform="translate(-847 -156)">
        <path
          d="M848 356.5C848 245.767 937.543 156 1048 156 1158.46 156 1248 245.767 1248 356.5 1248 467.233 1158.46 557 1048 557 937.543 557 848 467.233 848 356.5Z"
          fill={bgColor}
          fillRule="evenodd"
        />
        <path
          d="M972 209C972.909 210.556 973.937 212.045 974.727 213.668 975.156 214.548 975.208 215.588 975.636 216.468 976.783 218.824 977.582 219.399 979.273 221.136 979.781 223.224 981.114 229.107 982 230.471L983.818 233.272C984.121 234.517 984.368 235.777 984.727 237.006 985.278 238.891 986.081 240.698 986.545 242.608 986.849 243.852 987.111 245.108 987.455 246.342 987.718 247.288 988.131 248.188 988.364 249.142 988.738 250.682 988.938 252.261 989.273 253.81 989.544 255.063 989.911 256.292 990.182 257.544 991.886 265.421 990.546 260.353 992 271.547 992.204 273.12 992.606 274.659 992.909 276.215 994.649 303.01 992.796 276.804 995.636 308.889 996.767 321.657 996.13 315.748 997.455 326.627 997.758 332.539 997.953 338.458 998.364 344.364 998.559 347.175 999.024 349.96 999.273 352.766 999.63 356.806 999.879 360.857 1000.18 364.902 1000.48 374.86 1000.76 384.819 1001.09 394.775 1001.36 402.867 1002 410.951 1002 419.047 1002 442.077 1001.64 465.107 1001.09 488.13 1001.03 490.638 1000.63 493.132 1000.18 495.598 998.29 505.958 998.364 500.186 998.364 504"
          stroke={color}
          strokeWidth="40"
          strokeMiterlimit="8"
          fill="none"
          fillRule="evenodd"
        />
        <path
          d="M1002 342C1003.56 340.753 1005.08 339.456 1006.67 338.258 1007.57 337.583 1008.68 337.182 1009.47 336.387 1010.27 335.592 1010.47 334.283 1011.34 333.581 1012.11 332.965 1013.27 333.086 1014.15 332.645 1016.99 331.22 1018.39 329.334 1020.69 327.032 1021.62 326.097 1022.39 324.96 1023.49 324.226L1029.09 320.484C1029.41 319.548 1029.33 318.375 1030.03 317.677 1031.62 316.087 1035.64 313.935 1035.64 313.935 1037.5 308.323 1035.01 313.312 1039.37 310.194 1040.81 309.168 1041.64 307.43 1043.11 306.452 1044.04 305.828 1044.91 305.083 1045.91 304.581 1046.79 304.14 1047.85 304.124 1048.72 303.645 1059.38 297.71 1051.03 302.162 1056.19 298.032 1057.07 297.33 1058.12 296.864 1058.99 296.161 1059.68 295.61 1060.16 294.82 1060.86 294.29 1062.66 292.941 1064.6 291.796 1066.47 290.548 1067.4 289.925 1068.48 289.472 1069.27 288.677 1069.89 288.054 1070.38 287.26 1071.14 286.806 1071.98 286.299 1073.01 286.183 1073.94 285.871 1082.94 276.862 1073.37 285.958 1080.48 280.258 1087.14 274.926 1076.53 282.275 1085.15 276.516 1085.78 275.581 1086.32 274.588 1087.02 273.71 1088.54 271.806 1089.61 271.357 1091.69 269.968 1096.68 262.484 1090.14 271.527 1096.36 265.29 1098.28 263.378 1097.79 262.002 1100.1 260.613 1100.95 260.106 1101.97 259.989 1102.91 259.677 1103.53 258.742 1103.98 257.666 1104.77 256.871 1109.71 251.926 1106.19 259.425 1112.25 250.323 1116.89 243.355 1110.92 251.913 1116.92 244.71 1117.64 243.846 1117.91 242.606 1118.79 241.903 1119.56 241.287 1120.66 241.28 1121.59 240.968 1124.24 233.018 1120.55 242.711 1124.39 236.29 1127.4 231.27 1122.55 235.339 1128.13 231.613L1130 226"
          stroke={color}
          strokeWidth="40"
          strokeMiterlimit="8"
          fill="none"
          fillRule="evenodd"
        />
        <path
          d="M997 360C997.999 360.2 1003.01 361.001 1004.44 361.857 1009.95 365.165 1001.91 362.386 1009.08 365.571 1010.87 366.366 1014.66 367.429 1014.66 367.429 1016.39 369.156 1016.96 369.971 1019.31 371.143 1020.18 371.581 1021.17 371.762 1022.1 372.071 1026.59 376.556 1020.88 371.099 1026.74 375.786 1027.43 376.333 1027.9 377.118 1028.6 377.643 1030.39 378.982 1032.6 379.779 1034.18 381.357 1036.73 383.906 1035.21 382.937 1038.83 384.143 1039.45 384.762 1040 385.453 1040.69 386 1041.56 386.697 1042.78 386.986 1043.47 387.857 1044.09 388.621 1043.83 389.846 1044.4 390.643 1047.16 394.498 1047.38 394.111 1050.91 395.286 1052.15 396.524 1053.66 397.543 1054.63 399 1055.25 399.929 1055.61 401.089 1056.49 401.786 1057.25 402.397 1058.35 402.405 1059.28 402.714 1059.59 403.643 1059.66 404.686 1060.21 405.5 1062.24 408.552 1063.21 408.002 1065.78 410.143 1066.79 410.983 1067.73 411.92 1068.57 412.929 1069.29 413.786 1069.56 415.017 1070.43 415.714 1071.19 416.326 1072.29 416.333 1073.22 416.643 1076.8 422.008 1073.05 417.159 1077.87 421.286 1085.75 428.041 1077.97 422.594 1084.37 426.857 1086.05 429.377 1087.24 431.556 1089.95 433.357 1092.47 435.037 1094.65 436.226 1096.46 438.929 1097.07 439.857 1097.58 440.874 1098.31 441.714 1099.76 443.361 1101.41 444.809 1102.96 446.357L1104.82 448.214C1105.75 449.143 1106.88 449.907 1107.61 451 1108.23 451.929 1108.68 452.997 1109.47 453.786 1110.26 454.575 1111.41 454.917 1112.26 455.643 1117.95 460.52 1114.24 457.662 1117.83 462.143 1119.35 464.033 1120.41 464.478 1122.48 465.857 1122.79 466.786 1122.91 467.804 1123.41 468.643 1124.29 470.113 1126.79 471.514 1128.06 472.357 1131.64 477.722 1127.89 472.873 1132.71 477 1134.04 478.139 1136.42 480.714 1136.42 480.714 1136.73 481.643 1136.78 482.703 1137.35 483.5 1138.37 484.925 1141.07 487.214 1141.07 487.214L1142 490"
          stroke={color}
          strokeWidth="40"
          strokeMiterlimit="8"
          fill="none"
          fillRule="evenodd"
        />
      </g>
    </svg>
  )
}

LetterK.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
  bgColor: PropTypes.string,
}

LetterK.defaultProps = {
  size: `100px`,
  color: `#fff`,
  bgColor: `#000`,
}

export default LetterK
