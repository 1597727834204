exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-apps-clock-js": () => import("./../../../src/pages/apps/clock.js" /* webpackChunkName: "component---src-pages-apps-clock-js" */),
  "component---src-pages-apps-index-js": () => import("./../../../src/pages/apps/index.js" /* webpackChunkName: "component---src-pages-apps-index-js" */),
  "component---src-pages-apps-random-picker-js": () => import("./../../../src/pages/apps/random-picker.js" /* webpackChunkName: "component---src-pages-apps-random-picker-js" */),
  "component---src-pages-apps-random-string-js": () => import("./../../../src/pages/apps/random-string.js" /* webpackChunkName: "component---src-pages-apps-random-string-js" */),
  "component---src-pages-apps-stopwatch-js": () => import("./../../../src/pages/apps/stopwatch.js" /* webpackChunkName: "component---src-pages-apps-stopwatch-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */)
}

